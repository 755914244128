import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
}
from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import FullForm from "./Components/FullForm";
import Header from "./Components/Header";
import SubHeader from "./Components/SubHeader";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./styles.css";

require("typeface-hind");

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#06D8D5"
    },
    secondary: {
      main: "#06D8D5"
    },
    error: { main: "rgb(220, 20, 60);" },
    background: { paper: "#000" },
    text: { secondary: "#FFF" },
    typographie: {
      fontFamily: '"Hind,Roboto", "Helvetica", "Arial", sans-serif'
    }
  }
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Paper className="paper">
        <Header />
        <Switch>
        <Route path="/thanks">
          <Typography variant="h5" color="primary" className="thanks">Thanks for applying!</Typography>
          <Typography variant="body1" className="thanksBody">We have received your application and a member of our team will be in touch with you shortly!</Typography>
        </Route>
          <Route path="/">
            <SubHeader/>
            <FullForm />
          </Route>
        </Switch>
      </Paper>
      </Router>
    </ThemeProvider>
  );
}
