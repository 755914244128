import React from "react";

let imgClass = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  width: '100%',
  maxWidth: "440px"
};
const Header = () => {
  return (
    <div style={{ width: "100%" }}>
      <img
        style={imgClass}
        src="https://lh3.googleusercontent.com/BXetmhBvrPiIZLAu6MJD3uXo8pgTkeoHQ1phfW2EMvd7bLyFS-sJwzU-uOwmu7BHukG5I4HO_TMAqQxe9pdlmXCcHW2SiJ8U27_yQZ23WV3v3Myd2mKuA8dt6UhH=w1000"
      />
    </div>
  );
};

export default Header;
