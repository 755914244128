import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: '0 auto',
    textAlign: 'justify',
    paddingBottom: '40px'
  },

  title: {
    margin: "5px",
    width: "100%",
    textAlign: 'center'
  },

  body: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  }


}));

const SubHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        <Typography variant="h5" color="primary" className={classes.title}>CONTENT CREATOR APPLICATION</Typography>
        <Typography variant="body1" className={classes.body}>Adult Time offers many different opportunities for 18+ performers and content creators around the world. This includes traditional productions, live series, channel partnerships, and projects that we commission directly from you! Please complete this short application so we can have a better view on what you are able and interested in providing. A team member will follow up shortly to discuss different opportunities.</Typography>
      </div>

  );
}

export default SubHeader
