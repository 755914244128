import React, { useState } from "react";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  FormikCheckboxGroupField,
  FormikRadioGroupField,
  FormikTextField,
  FormikSwitchField
}
from "formik-material-fields";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  field: {
    margin: "5px",
    width: "100%"
  }
}));

const FullForm = () => {
  const [formState, setFormState] = useState();
  const classes = useStyles();
  const history = useHistory();

  const StyledTextField = props => {
    return (
      <Grid item xs={12} md={6}>
        <FormikTextField required className={classes.field} {...props} />
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
        <Formik
          initialValues={{
            name: "",
            email: "",
            twitter: "https://twitter.com/",
            location: "",
            agencyStatus:"independent",
            agencyName: "",
            equipments:"",
            shootTypes: [],
            shootTypesOther: "",
            channelPartnership:"",
            channelPartnershipUrl:"",
            creatorProgram:"",
            otherIdeas:"",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
            twitter: Yup.string()
              .url("Invalid Twitter URL")
              .notOneOf(['https://twitter.com/'])
              .required("Required"),
            location: Yup.string().required("Required"),
            agencyStatus: Yup.string().required("Required"),
            equipments: Yup.string().required("Required"),
            shootTypes: Yup.array(),
            channelPartnershipUrl: Yup.string().url("Invalid URL")
          })}
          onSubmit={(values, { setSubmitting }) => {
            setFormState('submitting');
            return window.fetch('https://orum1n11n6.execute-api.us-east-1.amazonaws.com/submit', {
            method: 'POST',
            body: JSON.stringify(values)
            })
            .then(response => response.json())
            .then(data => {
              console.log(data)
              setFormState(data.result)
              if(data.result==='success') history.push("/thanks");
              
            })
            .catch(err=>setFormState('error'));
          }}
        >
          {({ submitForm, isSubmitting, values, errors }) => (
            <Grid container spacing={6} alignItems="flex-start">
              <StyledTextField name="name" label="Performer Name:" />
              <StyledTextField name="email" label="Email:" />
              <StyledTextField
                label="Twitter URL:"
                name="twitter"
                helperText="The URL to your Twitter profile"
              />
              <StyledTextField
                label="Location:"
                name="location"
                helperText="Where are you located?"
              />
              <Grid item xs={12} md={6}>
              <Typography component="div" className={classes.field}>
              Are you independent or with an agency?
              </Typography>
                <FormikRadioGroupField
                  className={classes.field}
                  InputLabelProps={{
                    shrink: true
                  }}
                  name="agencyStatus"
                  options={[
                    {
                      value: "independent",
                      label: "Independent"
                    },
                    {
                      value: "agency",
                      label: "Agency"
                    }
                  ]}
                />

                {values.agencyStatus === "agency" && (
                  <FormikTextField
                    className={classes.field}
                    name="agencyName"
                    label="Agency Name:"
                    helperText="Please specify the name of the agency."
                  />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
              <Typography component="div" className={classes.field}>
                <Grid item xs={12}>Type of Shoots:</Grid>
                <Typography variant="caption">Please check all the different shoots you would be able and/or interested in being booked for.</Typography>
                  
                <Grid item xs={12}>
                  <FormikCheckboxGroupField
                    name="shootTypes"
                    multiple
                    options={[
                      { label: "Live Webcam Shows", value: "webcam" },
                      { label: "Solo Shoots", value: "solo" },
                      { label: "Solo Anal Shoots", value: "solo-anal" },
                      {
                        label:
                          "BJ, BG, or BG",
                        value: "boygirl"
                      },
                      {
                        label: "GG or GGA",
                        value: "girlgirl"
                      },
                      { label: "Other: Please Specify Below.", value: "other" }
                    ]}
                  />
                  {values.shootTypes.includes("other") && (
                    <FormikTextField
                      multiline
                      className={classes.field}
                      name="shootTypesOther"
                      label="Other Types of Shoots:"
                    />
                  )}
                </Grid>
               </Typography>
              </Grid>
              <StyledTextField
                name="equipments"
                label="Please Describe Your Gear:"
                multiline
                helperText="We understand that some of you may have very simple set ups (just your iphone) while others may have more advanced filming equipment. Just to help us get a better sense of your set up, please describe any cameras, lighting, or other gear you have access to and would want to use to work with us!"
              />
               <Grid item xs={12} md={6}>
                <Typography component="div" className={classes.field}>
                <Grid item xs={12}>Creator Sales Program:</Grid>
                <Typography variant="caption">We can create a custom tour featuring all of your past work in Adult Time for you to promote as a way to earn additional revenue on any new subscribers you send through your site.</Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Are you interested?</Grid>
                    <Grid item>
                     <FormikSwitchField
                        name="creatorProgram"
                        trueValue="Yes"
                        falseValue="No"
                        controlLabel="Yes"
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography component="div" className={classes.field}>
                <Grid item xs={12}>Channel Partnership Program:</Grid>
                <Typography variant="caption">Do you produce content for your own platforms that you'd be interested in offering as an official channel on Adult Time? (ig. OnlyFans, Modelhub, Manyvids, etc..) </Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Are you interested?</Grid>
                    <Grid item>
                     <FormikSwitchField
                        name="channelPartnership"
                        trueValue="Yes"
                        falseValue="No"
                        controlLabel="Yes"
                      />
                    </Grid>
                  </Grid>
                </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                <FormikTextField 
                  disabled={values.channelPartnership !== "Yes"}
                  className={classes.field}
                  name="channelPartnershipUrl"
                  label="Please provide a sample link to your content:"
                  helperText="*While we remain selective about our channel partnerships, we are interested in launching channels produced by content creators that we feel would be a good fit with our growing audience of paying porn viewers. These channels are non-exclusive, which means you can still monetize the content on your other platforms at the same time!"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormikTextField
                  className={classes.field}
                  name="otherIdeas"
                  label="Other Ideas or Talents to Share:"
                  className={classes.field}
                  multiline
                  helperText="If you have any other ideas or talents that you think could be a good fit to work for Adult Time, please let us know!"
                />
             </Grid>
             {errors &&(<Grid item xs={12}>{Object.keys(errors).map(key=><Alert severity="error">{key}: {errors[key]}</Alert>)}</Grid>)}
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  //disabled={Object.values(errors).length !== 0 || isSubmitting}
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </Grid>
               <Grid item xs={9}>
               {formState==='error'&& <Alert severity="error">There was an issue submitting the form. Review the content and please try again.</Alert>}
               </Grid>
            </Grid>
          )}
        </Formik>
    </div>
  );
};

export default FullForm;
